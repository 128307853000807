.login-holder {
  display: none;

  &--active {
    display: block;
  }
}

.login-project-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 320px;

  @include mq($bp-medium) {
    max-width: none;
  }

  &__item {
    @include font-size(16);
    background-color: $color-orange;
    flex: 0 0 100%;
    line-height: 1.2;
    margin: 0 0 10px;

    @include mq($bp-xsmall) {
      min-width: 320px;
    }

    @include mq($bp-medium) {
      flex: 0 0 47%;
    }

    &:hover {
      background-color: $color-orange-hover;
    }
  }

  &__link {
    color: $color-white;
    display: block;
    padding: 10px 0;
    text-decoration: none;
  }
}
