#{$form-class}__field {
  @include font-size(14);
  -webkit-appearance: none;
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: $color-grey-dark;
  display: inline-block;
  float: left;
  font-family: $font-default;
  font-weight: 300;
  height: 48px;
  line-height: 48px;
  margin: 0;
  padding: 0 10px;
  width: 100%;

  @include mq($bp-large + 1) {
    @include font-size(18);
  }

  &::-ms-clear {
    display: none;
  }

  &.placeholder {
    color: $color-grey;
    font-style: italic;
  }

  &.error {
    border-color: $color-status-error;
    color: $color-status-error;
  }

  &--is-disabled {
    background: $color-grey-lighter;
    border: 1px solid $color-grey-light;
  }

  &--sample {
    float: right;
    height: 32px;
    line-height: 32px;
    margin-left: 5px;
    max-width: 100px;
    position: relative;
    top: -7px;
    vertical-align: top;
    width: auto;

    @include mq($bp-small) {
      max-width: calc(100% - 20px);
    }

    &.form-item__field--tax {

      @include mq($bp-small - 1, false, max) {
        float: left;
        margin-left: 0;
        margin-top: 5px;
        max-width: inherit;
        top: 0;
        width: 100%;
      }

      @include mq($bp-small) {
        max-width: inherit;
        width: 300px;
      }

      @include mq($bp-medium) {
        max-width: inherit;
        min-width: 180px;
        width: 100%;
      }

      @include mq(800) {
        min-width: 200px;
      }

      @include mq(900) {
        min-width: 260px;
      }

      @include mq(1100) {
        min-width: 380px;
      }

      .fc-modal & {

        @include mq($bp-small) {
          max-width: none;
          min-width: 0;
          width: auto;
        }

        @include mq($bp-large) {
          @include mq(1300 - 1, false, max) {
            float: left;
            margin-left: 0;
            margin-top: 5px;
            max-width: inherit;
            top: 0;
            width: 100%;
          }
        }
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &--xsmall {
    max-width: 120px !important;
    width: 30%;

    .form-item__field + & {
      margin-left: 2%;
      width: 28%;
    }
  }

  &--small {
    width: 30%;

    .form-item__field + & {
      margin-left: 2%;
      width: 28%;
    }
  }

  &--medium {
    width: 49%;

    .form-item__field + & {
      margin-left: 2%;
      width: 49%;
    }
  }

  &--large {
    width: 70%;

    .form-item__field + & {
      margin-left: 2%;
      width: 68%;
    }
  }

  &--inline {
    @include font-size(16);
    background-color: $color-grey-lighter;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='6'%3E%3Cpath fill='%23a6a6a6' d='M9.2 1.6L4.9 5.9s-.2.1-.3.1-.1-.1-.2-.1L.1 1.6c0-.1-.1-.2-.1-.3s.1-.2.1-.3l1-.9.2-.1s.2.1.3.1l3.1 3.1L7.8.1S7.9 0 8 0s.2.1.3.1l1 .9c.1.1.1.2.1.3s-.1.2-.2.3z'/%3E%3C/svg%3E") !important;
    border-color: $color-grey-light;
    color: $color-secondary;
    display: inline-block;
    float: none;
    height: 27px;
    line-height: 27px;
    padding: 0 20px 0 5px !important;
    width: auto;

    // .ie10-11 & {
    //   padding-right: 0 !important;
    // }
  }

  &--text--icon {
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23E84F23' d='M3.6 13.7l.9-.9-2.3-2.3-.9.9v1.1h1.3v1.3h1zm5.2-9.2c0-.1-.1-.2-.2-.2s-.1 0-.2.1L3 9.8v.1c0 .1.1.2.2.2s.1 0 .2-.1l5.4-5.4c-.1.1 0 0 0-.1zm-.6-1.9l4.1 4.1L4.1 15H0v-4.1l8.2-8.3zm6.8 1c0 .3-.1.6-.4.9L13 6.1 8.9 2 10.5.4c.3-.3.5-.4.9-.4.3 0 .7.1.9.4l2.3 2.3c.3.3.4.6.4.9z'/%3E%3C/svg%3E");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    padding-right: 40px;

    &.form-item__field--sample {
      background-position: 10px 50%;
      max-width: 100px;
      padding-left: 40px;
      padding-right: 10px;
      text-align: right;

      @include mq($bp-small) {
        max-width: 120px;
      }
    }
  }

  &--textarea {
    display: block;
    height: 180px;
    line-height: 1.5;
    max-height: 600px;
    min-height: 180px;
    overflow-y: auto;
    padding: 10px;
    resize: vertical;
  }

  &--select {
    background-color: $color-white;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='11'%3E%3Cpath fill='%23E84F23' d='M16.9 2.9L9 10.8c-.1.1-.3.2-.5.2s-.3-.1-.5-.2L.2 2.9c-.1-.1-.2-.3-.2-.5s.1-.3.2-.5L2 .2c.1-.1.3-.2.4-.2s.3.1.5.2l5.6 5.6L14.1.2c.2-.1.4-.2.5-.2.2 0 .3.1.5.2l1.8 1.7c.1.1.2.3.2.5s-.1.4-.2.5z'/%3E%3C/svg%3E");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    padding-right: 0;
    font-family: $font-default;

    &::-ms-expand {
      display: none;
    }

    &.form-item__field--sample {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 21 20'%3E%3Cpath fill='%23E84F23' d='M14.2 7.1c0 .2-.1.4-.3.6s-.3.3-.6.3H.9c-.3 0-.5-.1-.6-.3S0 7.4 0 7.1s.1-.4.3-.6L6.5.3c.2-.2.4-.3.6-.3.2 0 .4.1.6.3L14 6.5c.1.2.2.4.2.6zM0 12.9c0-.2.1-.4.3-.6.1-.2.3-.3.6-.3h12.4c.2 0 .4.1.6.3.2.2.3.4.3.6 0 .2-.1.4-.3.6l-6.2 6.2c-.1.2-.3.3-.6.3-.2 0-.4-.1-.6-.3L.3 13.5c-.2-.2-.3-.4-.3-.6z'/%3E%3C/svg%3E");
      padding-right: 20px;

      // .ie10-11 & {
      //   padding-right: 0;
      // }
    }
  }

  .band__content &--text,
  .band__content &--select {
    @include mq($bp-medium) {
      max-width: 70%;
    }
  }
}

.clearinput {
  @include font-size(18);
  background: $color-white;
  border-radius: 50%;
  color: $color-grey-light;
  cursor: pointer;
  display: none;
  height: 26px;
  left: 100%;
  line-height: 27px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  top: 50%;
  transform: translate(-32px, -50%);
  width: 26px;

  &--large {
    left: 70%;
  }

  &:hover {
    color: $color-secondary;
  }
}
