@import '../variables';
@import '../mixins';

.login-logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;

  @include mq($bp-medium) {
    height: 95px;
  }

  .logo {

    svg {
      width: 140px;

      @include mq($bp-medium) {
        width: 260px;
      }
    }
  }

  .payoff {

    svg {
      height: 60px;

      @include mq($bp-medium) {
        height: 95px;
      }

      @include mq(1280) {
        height: 120px;
        margin-top: 20px;
      }
    }
  }
}
