.tags {
  @include clearfix();
  margin-bottom: 10px;
  padding-top: 10px;

  &__item {
    @include font-size(14);
    background: $color-primary;
    border-radius: 3px;
    color: $color-white;
    display: inline;
    float: left;
    margin: 0 5px 5px 0;
    padding: 3px 10px;
  }
}
