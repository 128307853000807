$tooltip-arrow-width:   5px;
$tooltip-padding:       5px;
$tooltip-background:    $color-grey-dark;
$tooltip-text:          $color-white;

[rel^="tooltip"] {
  color: $color-orange;
  cursor: pointer;
  text-decoration: none;
}

.tooltip {
  @include font-size(14);
  background: $tooltip-background;
  color: $tooltip-text;
  line-height: 1.2;
  padding: $tooltip-padding ($tooltip-padding * 2);
  position: absolute;
  text-align: left;
  z-index: 100;

  &::after {
    border-left: $tooltip-arrow-width solid transparent;
    border-right: $tooltip-arrow-width solid transparent;
    border-top: $tooltip-arrow-width solid $tooltip-background;
    bottom: - $tooltip-arrow-width;
    content: '';
    height: 0;
    left: 50%;
    margin-left: - $tooltip-arrow-width;
    position: absolute;
    width: 0;
  }

  &.top::after {
    border-bottom: $tooltip-arrow-width solid $tooltip-background;
    border-top-color: transparent;
    bottom: auto;
    top: - $tooltip-arrow-width;
  }

  &.left::after {
    left: ($tooltip-padding * 2) + $tooltip-arrow-width;
    margin: 0;
  }

  &.right::after {
    left: auto;
    margin: 0;
    right: ($tooltip-padding * 2) + $tooltip-arrow-width;
  }
}
