.fl-label {
  float: left;
  display: inline;
  @include text-overflow();

  &.error {
    color: $color-status-error;
  }

  &--xsmall {
      max-width: 120px !important;
      width: 30%;

      .fl-label + & {
        margin-left: 2%;
        width: 28%;
      }
    }

    &--small {
      width: 30%;

      .fl-label + & {
        margin-left: 2%;
        width: 28%;
      }
    }

    &--medium {
      width: 49%;

      .fl-label + & {
        margin-left: 2%;
        width: 49%;
      }
    }

    &--large {
      width: 70%;

      .fl-label + & {
        margin-left: 2%;
        width: 68%;
      }
    }
}
