// Media Queries
// Info: http://alwaystwisted.com/post.php?s=2013-04-01-my-media-query-mixin
@mixin mq($point, $IE9: false, $query1: min, $query2: width) {
  @if $IE9 == true{
    .no-mediaqueries & {
      @content;
    }

    @media screen and (#{$query1}-#{$query2}: $point+px) {
      @content;
    }
  } @else {
    @media screen and (#{$query1}-#{$query2}: $point+px) {
      @content;
    }
  }
}

@mixin orient($orientation: landscape) {
  @media (orientation: $orientation) {
    @content;
  }
}

@mixin retina() {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
    @content;
  }
}

// Clearfix
@mixin clearfix() {
  // & {
  //   *zoom: 1;
  // }

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin unselectable() {
  user-select: none;
}

@mixin font-size($font-default-size: 16) {
  // scss-lint:disable DuplicateProperty
  font-size: #{$font-default-size}px;
  font-size: #{$font-default-size / 10}rem;
  // scss-lint:enable DuplicateProperty
}

@mixin base-font() {
  font-family: $font-default;
  font-weight: 300;
  line-height: $font-lineheight;
}

@mixin title-font() {
  font-family: $font-title;
  line-height: $font-lineheight-title;
  font-weight: bold;
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin font-awesome($icon) {
  font-family: 'FontAwesome';
  content: $icon;
  display: inline-block;
}


// Color functions
@function tint($color, $percent) {
  @return mix($color-white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
  @return mix($color-black, $color, $percent);
}
