.video-player {
  margin-bottom: 40px;

  &__wrapper {
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.videoWrapper iframe {
  display: block;
}

.video.video--fit .videoWrapper {
  padding-bottom: 52.6%;
  position: relative;
  width: 100%;
}

.video.video--fit iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
