// scss-lint:disable VendorPrefix
::-webkit-input-placeholder {
  color: $color-grey;
  font-style: italic;
}

::-moz-placeholder {
  color: $color-grey;
  font-style: italic;
}

:-ms-input-placeholder {
  color: $color-grey;
  font-style: italic;
}
// scss-lint:enable VendorPrefix
