.nav {
  @include clearfix();
  display: block;
  padding: 0 20px;

  .menu {
    display: inline-block;

    &__item {
      display: inline-block;
    }

    &__link {
      text-decoration: none;
    }
  }

  &--top {
    @include font-size(14);
    border-bottom: 1px solid $color-grey-light;
    display: none;
    text-align: right;

    .style-pbp & {
      height: 32px;
    }

    @include mq($bp-medium) {
      display: block;
    }

    .login & {

      @include mq($bp-medium) {
        display: none;
      }
    }

    .menu {
      margin: 5px 0;
    }

    .menu__item {
      margin-left: 10px;
    }

    .menu__link {
      color: $color-blue;

      &:hover,
      &:focus {
        color: $color-blue-hover;
      }
    }
  }

  &--band,
  &--main {
    text-align: center;

    .menu__item {

      &:hover {
        .menu__icon,
        .menu__link {
          border-color: $color-orange-hover;
          color: $color-orange-hover;
        }
      }

      &--selected {
        .menu__icon,
        .menu__link {
          border-color: $color-blue;
          color: $color-blue;
        }
      }
    }

    .menu__link {
      @include font-size(0);
      color: $color-orange;

      &:hover,
      &:focus {
        color: $color-orange-hover;
      }
    }

    .menu__icon {
      @include font-size(24);
      border: 1px solid $color-orange;
      border-radius: 50%;
      color: $color-orange;
      display: block;
      height: 50px;
      line-height: 50px;
      margin: 0 auto 5px;
      text-align: center;
      width: 50px;

      @include mq($bp-medium) {
        display: block;
      }

      .icon::before {
        // position: relative;
        // top: 5px;
        vertical-align: middle;
      }
    }

    .menu__text {
      @include font-size(12);
      display: none;
      line-height: 20px;

      @include mq($bp-medium) {
        @include font-size(14);
        display: block;
      }
    }
  }

  &--band {

    @include mq($bp-medium) {
      .menu__icon {
        @include font-size(42);
        height: 100px;
        line-height: 100px;
        margin-bottom: 10px;
        width: 100px;
      }

      .menu__text {
        @include font-size(16);
      }
    }

    .menu {
      @include clearfix();
      width: 100%;
    }

    .menu__item {
      float: left;
      margin-bottom: 20px;
      width: 50%;

      @include mq($bp-small) {
        width: 25%;
      }
    }

    .menu__text {
      display: block;
    }
  }

  &--main {
    border-bottom: 1px solid $color-grey-light;

    &::before {
      // scss-lint:disable DuplicateProperty
      background: $color-grey-light;
      // background: linear-gradient(to right, $color-blue 0%, $color-green 50%, $color-orange 100%);
      // scss-lint:enable DuplicateProperty
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
    }

    .menu {
      display: none;

      @include mq($bp-medium) {
        display: block;
        float: right;
        margin: 20px 0 16px;
      }

      .login & {
        margin: 20px 0;
      }
    }

    .menu__item {
      display: inline-block;
      margin: 0 10px;

      @include mq($bp-medium) {
        margin: 0 0 0 20px;
        width: 116px;
      }
    }
  }

  &--menu {
    @include font-size(16);
    font-weight: 300;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: max-height 500ms;

    @include mq($bp-medium) {
      max-height: none;
      padding: 0 20px;
    }

    &.nav--visible {
      max-height: 800px;
    }

    .login & {

      @include mq($bp-medium) {
        display: none;
      }
    }

    .menu {
      display: block;
      margin: 0 0 8px;

      @include mq($bp-medium) {
        margin: 20px 0;
      }
    }

    .menu__divider {
      background-color: $color-grey-light;
      clear: both;
      display: block;
      height: 3px;

      @include mq($bp-medium) {
        display: none;
      }
    }

    .menu__item {
      border-bottom: 1px solid $color-grey-light;
      display: block;

      @include mq($bp-medium) {
        border-bottom: 0;
        display: inline-block;
        margin: 0 20px 0 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      &--toggle {
        background-color: $color-orange;
      }

      &--icon {
        color: $color-orange;
        float: left;
        font-size: 12px;
        text-align: center;
        width: 33.33%;

        .menu__link {
          color: $color-orange;
          padding-left: 2px;
          padding-right: 2px;
          padding-top: 15px;
        }

        .icon {
          border: 1px solid $color-orange;
          border-radius: 50%;
          display: block;
          font-size: 20px;
          height: 50px;
          line-height: 50px;
          margin: 0 auto 5px;
          text-align: center;
          width: 50px;
        }
      }
    }

    .menu__link {
      color: $color-grey-dark;
      display: block;
      padding: 8px 20px;

      @include mq($bp-medium) {
        color: #032935;
        display: inline-block;
        padding: 0;

        &:hover,
        &:focus {
          color: $color-orange-hover;
        }
      }

      &--selected {
        color: $color-blue;
      }
    }

    // .hide-medium .menu__link {
    //   @include font-size(16);
    //   color: $color-orange;
    //   padding-bottom: 15px;
    //   padding-top: 15px;

    //   &--selected {
    //     color: $color-blue;
    //   }
    // }

    .menu__item--toggle .menu__link {
      @include font-size(20);
      color: $color-white;
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .icon::before,
    .fa::before {
      display: inline-block;
      font-size: 120%;
      text-align: center;
      vertical-align: middle;
      width: 30px;
    }

    .fa-home,
    .icon-home {
      display: none;

      @include mq($bp-medium) {
        display: inherit;
        position: relative;
        top: -2px;

        &::before { width: auto; }
      }
    }
  }

  &--footer {
    @include font-size(14);
    padding: 0;

    .copyright,
    .menu {
      display: block;
      margin-bottom: 10px;
      text-align: center;

      @include mq($bp-medium) {
        display: inline-block;
        margin-bottom: 0;
        text-align: left;
      }
    }

    .menu {

      @include mq($bp-medium) {
        float: right;
      }
    }

    .menu__item {
      margin-left: 10px;
    }

    .menu__link {
      color: $color-text;

      &:hover {
        color: $color-orange;
      }
    }
  }

  &--breadcrumbs {
    @include font-size(13);
    color: $color-grey;
    padding: 15px 0 0;

    @include mq($bp-small) {
      @include font-size(16);
    }

    .link {
      color: $color-grey;
      text-decoration: none;
    }
  }
}

.navigation {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  min-height: 115px;
  border-bottom: 1px solid $color-grey-light;

  .logos {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 500px;
    margin: 0;
  }

  .divider {
    margin: 0 20px 0 0;
    width: 30px;
    align-self: stretch;
    display: none;

    @include mq($bp-small) {
      display: flex;
    }

    svg {
      flex: 0 auto;
      width: 100%;
    }
  }

  .logo {
    flex: 1 auto;
    margin: 0 20px 0 0;

    svg {
      display: block;
    }
  }

  .payoff {
    display: none;

    @include mq($bp-medium) {
      display: block;
      width: 115px;
    }
  }

  .buttons {
    flex: 0 auto;
    font-size: 10px;
    position: relative;
    text-transform: uppercase;

    @include mq($bp-medium) {
      display: none;
    }

    .mobile-menu-toggle {
      color: $color-orange;
      text-decoration: none;

      .fa {
        display: block;
        font-size: 28px;
        text-align: center;
      }
    }
  }
}
