.fc-modal {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &__container {

    @include mq($bp-large) {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      height: 100%;
      max-height: 600px;
      overflow: hidden;

      .no-flexbox & {
        height: 600px;
        overflow: auto;
      }
    }
  }

  &__item {

    @include mq($bp-large) {
      flex: 0 0 38%;
      overflow: auto;
      overflow-x: hidden;

      &:nth-child(1) {
        flex: 1 0 24%;
      }

      &:nth-child(2) {
        // flex: 1 0 40%;
        height: 600px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .no-flexbox & {
        display: inline;
        float: left;
        height: 600px;
        overflow: auto;
        overflow-x: hidden;
        width: 35%;

        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }
    }

    &:nth-child(2) {
      background: shade($color-grey-lighter, 5);
    }
  }

  &__content {
    @include clearfix();
    padding: 20px;
  }

  &__logo {
    margin-bottom: $margin-bottom;
    max-width: 240px;
    text-align: left;
    width: 100%;

    @include mq($bp-small) {
      display: inline;
      float: left;
      margin-right: 10px;
      width: 200px;
    }

    @include mq($bp-large) {
      display: block;
      float: none;
      margin-right: 0;
      width: 100%;
    }

    img {
    //&__image {
      // display: block;
      // width: 100%;
      margin: 0;
    }
  }

  &__info {

    @include mq($bp-small) {
      display: inline;
      float: left;
      width: calc(100% - 230px);
    }

    @include mq($bp-large) {
      display: block;
      float: none;
      width: inherit;
    }

    .title {
      margin-bottom: 0;
    }
  }
}
