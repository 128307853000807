.noUi-pips,
.noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: $color-grey;
}

.noUi-value {
  @include font-size(14);
  position: absolute;
  text-align: center;
}

.noUi-marker {
  position: absolute;
  background: $color-grey-light;
}

.noUi-pips-horizontal {
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  padding-top: 10px;
  transform: translateX(-50%);
}

