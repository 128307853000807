@import '../variables';
@import '../mixins';

.login-box {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, .1);
  padding: 20px 20px 120px;
  position: relative;
  overflow: hidden;
  max-width: 300px;
  text-align: left;

  .title {
    @include font-size(24);
  }

  .form {
    position: relative;
    z-index: 2;

    label {
      display: block;
      margin-bottom: 2px;
    }

    .input {
      border: 1px solid #ccc;
      text-align: left;
      border-radius: 5px;

      &:focus {
        border: 1px solid #bbb;
        box-shadow: 0 0 15px rgba($color-blue, .2);
      }
    }

    .actions {
      text-align: center;
      margin-bottom: 10px;
    }

    .btn {
      @include font-size(16);
      height: 40px;
      // line-height: 28px;
      padding: 0 10px;
      // width: 100%;
      margin: 0 auto;
      line-height: 1;
      @include title-font();
      font-style: italic;
      text-transform: uppercase;
    }
  }

  .info {
    background: #000;
    color: #fff;
    border-radius: 5px;
    padding: 10px 10px 10px 40px;
    @include font-size(12);
    margin-bottom: 10px;
    position: relative;

    .fa {
      position: absolute;
      left: 12px;
      top: 8px;
      @include font-size(20);
      line-height: 1;
      color: $color-blue;
    }

    p {
      margin-bottom: 0;

      a {
        color: #fff;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .icon {
    position: absolute;
    z-index: 1;
    right: 20px;
    bottom: 0px;
    width: 120px;
    height: 120px;

    img {
      position: relative;
      display: block;
      width: 100%;
      z-index: 3;
    }

    &::after {
      position: absolute;
      display: block;
      height: 90px;
      width: 90px;
      border: 15px solid $color-green;
      content: '';
      top: 0;
      left: -40px;
      border-radius: 99999px;
      opacity: .25;
    }
  }
}
