.svblocks {
  margin: 0 0 $margin-bottom * 2;

  &__block {
    border-radius: 6px;
    box-shadow: 0 0 15px rgba($color-black, .2);
    box-sizing: border-box;
    float: left;
    margin: 0 0 $margin-bottom * 2;
    padding: 20px 20px 0;
    text-align: center;
    width: 100%;

    @include mq($bp-medium) {
      margin: 0 (3.33% / 2);
      width: 30%;

      &:first-child {
        margin-left: 0;
        margin-right: 3.33%;
      }

      &:last-child {
        margin-left: 3.33%;
        margin-right: 0;
      }
    }
  }

  &__icon {
    @include font-size(42);
    color: $color-orange;
  }

  .cta {
    @include font-size(14);
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    @include mq($bp-large) {
      @include font-size(18);
    }
  }

  .title {
    @include font-size(24);
  }
}
