.site {
  background: $color-white;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1602px;
  min-width: 320px;
  overflow: hidden;
  width: 100vw;

  @include mq($bp-medium) {
    padding-top: 212px;
  }

  @include mq(1602) {
    border-left: 1px solid $color-grey-light;
    border-right: 1px solid $color-grey-light;
  }

  .login & {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    @include mq($bp-medium) {
      min-height: 100vh;
      padding-top: 115px;
    }
  }
}

.band {
  min-width: 320px;
  position: relative;

  .band__holder {
    margin: 0 auto;
    max-width: 1200px;
  }

  &--nav {
    background-color: $color-white;

    @include mq($bp-medium) {
      left: 0;
      margin-bottom: 0;
      position: fixed;
      right: 0;
      top: 0;
      transform: translateY(0);
      transition: transform 150ms ease-in-out;
      z-index: 11;

      .scroll & {
        transform: translateY(-148px);
      }
    }

    @include mq(1600) {
      left: 50%;
      left: calc(50% - 1px);
      margin-left: -800px;
      width: 1600px;
    }

    @include mq(1602) {
      border-left: 1px solid $color-grey-light;
      border-right: 1px solid $color-grey-light;
    }
  }

  &.model5,
  &.model7 {

    .band__content {
      text-align: inherit !important;
    }
  }



  &--item {
    border-bottom: 1px solid $color-grey-light;

    @include mq($bp-medium) {
      border-bottom: 0;
    }

    .band__holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .band__content {
      @include clearfix();
      flex: 0 0 auto;
      margin: 0;
      max-width: none;
      padding-bottom: 40px;
      padding-top: 60px;

      // Alignment
      &--centered {
        margin: 0 auto;
        text-align: center;
      }

      &--left {
        text-align: left;
      }

      &--right {
        align-self: flex-end;
        max-width: 100%;
        text-align: right;
      }

      &--small {

        @include mq($bp-medium) {
          width: 60%;
        }

        @include mq($bp-large) {
          width: 40%;
        }
      }

      &--medium {

        @include mq($bp-medium) {
          width: 60%;
        }
      }

      &--large {

        @include mq($bp-medium) {
          width: 80%;
        }
      }

      &--white {
        color: $color-white;

        .link {
          color: $color-white;
        }
      }
    }

    .columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @include mq($bp-medium) {
        flex-wrap: nowrap;

        &--floating {

          .columns__column {
            min-height: 10px;

            &--content {
              flex: 0 0 30%;

              .flexbox & .vcenter {
                background-color: $color-white;
                left: 0;
                margin-top: -10px;
                padding: $margin-bottom $margin-bottom 0 0;
                position: absolute;
                right: -50%;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
                z-index: 10;
              }
            }

            &--image {
              flex: 0 0 70%;
            }
          }
        }
      }

      &__column {
        box-sizing: border-box;
        flex: 0 0 100%;
        position: relative;

        @include mq($bp-medium) {
          flex: 0 0 50%;
        }

        .no-flexbox & {

          @include mq($bp-medium) {
            float: left;
            width: 50%;
          }
        }

        &--vcenter {
          align-items: center;
          display: flex;
          justify-content: center;
        }
      }

      .vcenter {
        width: 100%;
      }

      .columns__column--content + .columns__column--image,
      .columns__column--image + .columns__column--content,
      .columns__column--content + .columns__column--video,
      .columns__column--video + .columns__column--content {

        @include mq($bp-medium) {
          padding-left: 40px;
        }
      }
    }

    .no-flexbox &.model5 {
      @include clearfix();
      text-align: left;

      @include mq($bp-small) {
        .band__content {
          float: right;
        }
      }
    }
  }

  &.band--border {
    border-bottom: 1px solid $color-grey-light !important;
  }

  &.model13 {


    @include mq($bp-medium) {

      .columns__column--content {
        order: 1;
      }

      .columns .columns__column--image {
        order: 0;
        padding-left: 0 !important;
      }

      .vcenter {
        left: -50% !important;
        padding: $margin-bottom 0 0 $margin-bottom !important;
        right: auto !important;
      }
    }
  }

  &.model18 {

    @include mq($bp-medium) {
      .vcenter {
        left: -50%;
        padding: $margin-bottom 0 0 $margin-bottom;
        right: 0;
      }
    }
  }

  &.model19 {
    .band__content {
      padding-bottom: 0;
      padding-top: 60px;
      margin-top: 80px !important;
      margin-bottom: 40px !important;
      background: $color-white;
      max-width: 500px;

      margin-left: 20px;
      margin-right: 20px;

      @include mq(540) {
        margin-left: auto;
        margin-right: auto;
      }

      &::before {
        position: absolute;
        left: 50%;
        top: -53px;
        margin-left: -100px;
        width: 200px;
        height: 105px;
        background: url('../images/startweken-logo.png') 50% no-repeat;
        background-size: contain;
      }
    }
  }

  &.model4,
  &.model4b,
  &.model4c {
    display: flex;
    flex-direction: column;

    .band__holder {
      width: 100%;
    }

    @include mq($bp-medium - 1, false, max) {
      .band__holder {
        height: auto !important;
      }

      .band__content {
        background-color: transparentize($color-white, .5);

        &--white {
          background-color: transparentize($color-black, .5);
        }
      }
    }
  }

  &.model20 {
    .band__holder {
      max-width: none;
    }
    .band__content {
      padding: 0;
    }
    .columns {
      display: flex;
      align-items: center;
    }
    .columns__column--content {
      padding: 20px;

      @include mq($bp-medium) {
        max-width: 50%;
      }
    }
    .columns__column--image {
      flex: 1 50%;

      @include mq($bp-medium) {
        max-width: 50%;
      }

      figure {
        margin: 0;
      }
    }
  }

  &.model20201 {
    .band__holder {
      max-width: none;
    }
    .band__content--split {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      @include mq($bp-small) {
        padding: 0 50px;
        flex-wrap: nowrap;
      }

      > div {
        flex: 0 100%;
        padding: 0 40px;

        @include mq($bp-small) {
          flex: 0 50%;
        }
      }
    }
  }

  &.model20202 {
    .band__holder {
      max-width: none;
    }
    .band__content--split {
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      @include mq($bp-small) {
        flex-wrap: nowrap;
      }

      > figure {
        flex: 0 100%;
        position: relative;
        margin: 0;

        @include mq($bp-small) {
          flex: 0 50%;
        }

        img {
          width: 100%;
          object-fit: contain;
          display: block;
        }

        .cta {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &.band--newvid {
    @include mq(1600) {
      .columns__column--content {
        padding-left: 90px;
      }
    }

    .videoWrapper iframe {
      width: 100%;
    }
  }

  &--content {
    background: $color-white;
    box-sizing: border-box;
    padding: 40px 0;

    + .band--item {

      .band__content {
        padding-top: 0;
      }
    }

    .band__content {
      max-width: 960px;
    }

    .page--2036 & .band__content,
    .page--3304 & .band__content,
    .page--3240 & .band__content {
      max-width: none;
    }

    .login & {
      align-items: center;
      background-color: $color-black;
      color: $color-white;
      display: flex;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: calc(100vh - 232px);
      padding: 40px 0;

      @include mq($bp-medium) {
        min-height: calc(100vh - 182px);
      }

      .band__content {
        text-align: center;
      }

      .content {
        margin: 0 auto;
        max-width: 400px;
        min-width: calc(100vw - 40px);

        @include mq(420) {
          min-width: 400px;
        }
      }

      .link {
        color: $color-white;
        text-decoration: underline;
      }
    }

    .post-login & {

      .content {
        max-width: 700px;
      }
    }
  }

  &--footer {
    border-top: 1px solid $color-grey-light;
    padding: 20px 0;

    .band__content {
      max-width: inherit;
    }

    .login & {
      flex: 0 0 auto;
    }
  }

  &__background {
    background-position: 100% 50%;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &--cover-left {
      background-position: 0 50%;
    }

    &--cover-center {
      background-position: 50%;
    }

    &--bl,
    &--br,
    &--tl,
    &--tr {
      background-repeat: no-repeat;
      background-size: contain;
      display: none;

      @include mq($bp-medium) {
        display: block;
      }
    }

    &--tl {
      background-position: top left;
    }

    &--tr {
      background-position: top right;
    }

    &--bl {
      background-position: bottom left;
    }

    &--br {
      background-position: bottom right;
    }
  }

  &__content {
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 20px;
    position: relative;
  }
}

.content {
  @include clearfix();
}

.preview {
  height: 500px;
  overflow-y: auto;
  padding: 20px;
  border: 2px solid #00a0d7;
  margin-bottom: 20px;

  .bold {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }
}

@import 'logos';
