.alert {
  border-radius: 2px;
  margin-bottom: $margin-bottom;
  padding: 10px 20px;

  .fc-modal & {
    clear: both;
    @include font-size(12);
    padding: 10px;
  }

  .title,
  .paragraph,
  .list {
    line-height: 1.4;
    margin-bottom: 0;
  }

  .paragraph,
  .list {
    color: $color-text;
  }

  &--error {
    background: tint($color-status-error, 90);
    border: 2px solid $color-status-error;

    .title {
      color: $color-status-error;
      font-size: 100%;
    }

    .link,
    i {
      color: $color-status-error !important;
    }
  }

  &--info {
    background: tint($color-status-info, 90);
    border: 2px solid $color-status-info;

    .title {
      color: $color-status-info;
    }

    .link,
    i {
      color: $color-status-info !important;
    }

    .fc-modal & {
      background: $color-grey-lighter;
      border: 2px solid shade($color-grey-lighter, 5);
    }
  }

  &--success {
    background: tint($color-status-ok, 90);
    border: 2px solid $color-status-ok;

    .title {
      color: $color-status-ok;
    }

    .link,
    i {
      color: $color-status-ok !important;
    }
  }

}
