html {
  font-size: 62.5%; // LATEN STAAN - NIET AANPASSEN
  font-smoothing: antialiased;
  height: 100%;
  tap-highlight-color: transparentize($color-black, .9);
  text-size-adjust: 100%;
}

body {
  @include base-font();
  @include font-size(16);
  background-color: $color-body;
  color: $color-text;
  margin: 0;
  overflow-x: hidden;

  @include mq($bp-large) {
    @include font-size(18);
  }
}

strong {
  font-weight: bold;
}

.hide-medium {

  @include mq($bp-medium) {
    display: none !important;
  }
}

.aaoinformatie {
  border: 1px solid $color-grey;
  height: 200px;
  margin-bottom: $margin-bottom * 2;
  overflow-y: scroll;
  padding: 20px 20px 0;
}

.cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__col {
    box-sizing: border-box;
    flex: 0 0 100%;

    @include mq($bp-medium) {
      box-sizing: border-box;
      flex: 0 0 50%;
      max-width: calc(50% - 10px);

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }
  }
}

.line {
  @include clearfix();
  background-color: $color-grey-light;
  height: 1px;
  margin-bottom: $margin-bottom * 2;
}
