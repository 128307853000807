$faq-padding: 25px;

.faq {
  text-align: left;

  + *:not(.faq) {
    margin-top: $margin-bottom;
  }

  &__question {
    cursor: pointer;
    margin-bottom: 5px;
    padding-left: $faq-padding;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      @include font-awesome($fa-var-plus-square-o);
      color: $color-orange;
      left: 0;
      position: absolute;
      top: 1px;
    }
  }

  &__answer {
    border-bottom: 1px solid $color-grey-light;
    color: tint($color-text, 20);
    display: none;
    margin-bottom: $margin-bottom;
    padding: 0 0 $margin-bottom $faq-padding;
  }

  &--open {

    .faq__question::before {
      @include font-awesome($fa-var-minus-square-o);
    }

    .faq__answer {
      display: block;
    }
  }
}
