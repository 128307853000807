.fc-results {
  border-bottom: 1px solid $color-grey-light;
}

.fc-sort {
  @include font-size(14);
  background: $color-white;
  border-bottom: 1px solid $color-grey-light;
  font-family: $font-title;
  height: 50px;
  overflow: hidden;
  padding: 0 20px 0 0;
  text-align: right;
  transform: translateZ(0);

  @include mq($bp-large) {
    @include font-size(16);
  }

  &__item {
    display: inline-block;
    line-height: 50px;
    transform: translateZ(0);

    &--desc {
      display: none;

      @include mq($bp-xsmall) {
        display: inline-block;
      }
    }
  }

  &__link {
    @include unselectable();
    color: $color-orange;
    line-height: 50px;
    margin-left: 10px;
    text-decoration: none;

    &:hover {
      color: $color-orange-hover;
    }

    &--active {
      font-weight: bold;

      &::after {
        @include font-awesome($fa-var-chevron-down);
        padding-left: .2em;
      }

      &--down {

        &::after {
          @include font-awesome($fa-var-chevron-up);
        }
      }
    }
  }
}

.result-tabs {

  &__item {
    display: none;
    float: left;
    margin: 0;
    padding: 0;

    @include mq($bp-medium) {
      display: inline;
    }
  }

  &__link {
    border-right: 1px solid $color-grey-light;
    color: $color-grey;
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 20px 0 32px;
    position: relative;
    text-decoration: none;

    @include mq($bp-large) {
      padding: 0 20px 0 46px;
    }

    .icon,
    .fa {
      @include font-size(24);
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    .icon {
      transform: translateY(-42%);
    }

    span {
      display: none;

      @include mq($bp-large) {
        display: inline;
      }
    }

    &:hover,
    &:focus {
      color: $color-orange;
    }

    &.active {
      background-color: $color-grey-lighter;
      color: $color-orange;
    }
  }
}

