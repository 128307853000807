.paragraph {
  margin-bottom: $margin-bottom;
  margin-top: 0;

  // scss-lint:disable IdSelector, SelectorFormat
  #aanmeldenForm & {
    line-height: 1.25;

    @include mq($bp-medium) {
      line-height: 1.5;
    }
  }
  // scss-lint:enable IdSelector, SelectorFormat

  &--intro {
    font-size: 120%;
  }

  .centered & {
    text-align: center;
  }

  span {
    color: $color-blue;
  }
}

sub {
  font-size: 80%;
  position: relative;
  top: 3px;
}

sup {
  font-size: 80%;
  position: relative;
  top: -3px;
}

strong,
b {
  font-weight: bold;
}

.aaoinformatie p {
  margin-bottom: $margin-bottom;
  margin-top: 0;
  font-size: 90%;
  line-height: 1.25;

  @include mq($bp-medium) {
    font-size: 100%;
    line-height: 1.5;
  }
}
