.show-modal {
  overflow: hidden;

  @include mq($bp-large) {
    overflow: auto;
  }
}

.modal {
  background: $color-white;
  box-shadow: 0 0 60px -10px transparentize($color-black, .2);
  display: none;
  height: 100%;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 600px;
  z-index: 100;

  @include mq($bp-xsmall) {
    height: 360px;
    top: 50%;
    transform: translateY(-50%);
  }

  @include mq($bp-small) {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--large {
    left: 0;
    top: 0;
    width: 1600px;

    @include mq($bp-xsmall) {
      height: 100%;
      top: 0;
      transform: none;
    }

    @include mq($bp-large) {
      height: 600px;
      top: 50%;
      transform: translateY(-50%);
    }

    @include mq(1600) {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .show-modal & {
    display: block;
  }

  &--overlay {
    background: transparentize($color-black, .6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;

    .show-modal & {
      display: block;
    }
  }

  &__content {
    box-sizing: border-box;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: $margin-bottom;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &--large &__content {
    padding: 0;
  }

  &__close-button {
    @include font-size(18);
    background: $color-secondary;
    border: 0;
    color: $color-white;
    padding: 5px 10px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    @include mq($bp-large) {
      background: $color-grey-light;
      color: $color-text;
    }

    &:hover,
    &:focus {
      background: $color-secondary;
      color: $color-white;
    }
  }
}

@import 'fc-modal';
