@import '../variables';
@import '../mixins';

.login-grid {
  min-height: 100vh;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  z-index: 999;
  // visibility: hidden;

  .row {
    width: 100%;
    // border: 1px solid green;

    &--top {
      height: 60px;
      margin-bottom: 20px;

      @include mq($bp-medium) {
        height: 95px;
      }
    }

    &--content,
    &--projects {
      flex: auto;
    }
  }
}

.login-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 180px);
  padding-bottom: 40px;

  .col {
    flex: 1 1 auto;
    max-width: 100%;
    flex-grow: 0;

    @include mq($bp-medium) {
      max-width: 50%;
    }

    &--login {
      // margin-right: 80px;
    }

    &--content {
      display: none;


      @include mq($bp-medium) {
        display: block;
        margin-left: 40px;
        margin-right: 10px;
      }

      @include mq(1280) {
        max-width: 720px;
        margin-left: 120px;
      }
    }

    &--projects {
      max-width: 720px;
      text-align: center;

      .login-project-selection {
        text-align: center;

        li {
          border-radius: 30px;
        }

        a {
          font-weight: bold;
          overflow: hidden;
          font-family: $font-title;
          font-style: italic;
          // text-transform: uppercase;
          border-radius: 30px;
        }
      }
    }
  }
}
