.login .form {

  .actions,
  .clearfix {
    @include clearfix();
    margin-bottom: 10px;
  }

  .button {
  //   border-radius: $default-border-radius;
  //   box-sizing: border-box;
  //   cursor: pointer;
    font-weight: 700;
  //   display: block;
    padding: 15px 0;
    text-transform: uppercase;
    width: 100%;
  }

  .input {
    @include font-size(16);
    -webkit-appearance: none;
    border: 1px solid $color-white;
    border-radius: 0;
    box-sizing: border-box;
    display: block;
    margin: 0;
    padding: 15px;
    text-align: center;
    width: 100%;

    &:focus {
      outline: 0;
    }
  }
}
