// // DO NOT EDIT DIRECTLY!
  //Generated by gulpfile.js/tasks/iconFont.js
  //from gulpfile.js/tasks/iconFont/template.scss

@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
       url('../fonts/icons.woff') format('woff'),
       url('../fonts/icons.ttf') format('truetype'),
       url('../fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon($content: '') {
  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: $content;
    font-family: 'icons';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
  }
}

.icon {
  @include icon;
}

// Save variable
$icon-android: "\EA01";

// Save mixin
@mixin icon--android {
  @include icon($icon-android);
}

// Expose as class
.icon.icon-android:before {
  content: $icon-android;
}

// Save variable
$icon-service: "\EA02";

// Save mixin
@mixin icon--service {
  @include icon($icon-service);
}

// Expose as class
.icon.icon-service:before {
  content: $icon-service;
}

// Save variable
$icon-pencil: "\EA03";

// Save mixin
@mixin icon--pencil {
  @include icon($icon-pencil);
}

// Expose as class
.icon.icon-pencil:before {
  content: $icon-pencil;
}

// Save variable
$icon-search: "\EA04";

// Save mixin
@mixin icon--search {
  @include icon($icon-search);
}

// Expose as class
.icon.icon-search:before {
  content: $icon-search;
}

// Save variable
$icon-nl: "\EA05";

// Save mixin
@mixin icon--nl {
  @include icon($icon-nl);
}

// Expose as class
.icon.icon-nl:before {
  content: $icon-nl;
}

// Save variable
$icon-list-paper: "\EA06";

// Save mixin
@mixin icon--list-paper {
  @include icon($icon-list-paper);
}

// Expose as class
.icon.icon-list-paper:before {
  content: $icon-list-paper;
}

// Save variable
$icon-home: "\EA07";

// Save mixin
@mixin icon--home {
  @include icon($icon-home);
}

// Expose as class
.icon.icon-home:before {
  content: $icon-home;
}

// Save variable
$icon-fitness-stap1: "\EA08";

// Save mixin
@mixin icon--fitness-stap1 {
  @include icon($icon-fitness-stap1);
}

// Expose as class
.icon.icon-fitness-stap1:before {
  content: $icon-fitness-stap1;
}

// Save variable
$icon-fitness-stap2: "\EA09";

// Save mixin
@mixin icon--fitness-stap2 {
  @include icon($icon-fitness-stap2);
}

// Expose as class
.icon.icon-fitness-stap2:before {
  content: $icon-fitness-stap2;
}

// Save variable
$icon-fitness-stap4: "\EA0A";

// Save mixin
@mixin icon--fitness-stap4 {
  @include icon($icon-fitness-stap4);
}

// Expose as class
.icon.icon-fitness-stap4:before {
  content: $icon-fitness-stap4;
}

// Save variable
$icon-fitness-stap3: "\EA0B";

// Save mixin
@mixin icon--fitness-stap3 {
  @include icon($icon-fitness-stap3);
}

// Expose as class
.icon.icon-fitness-stap3:before {
  content: $icon-fitness-stap3;
}

// Save variable
$icon-service-1: "\EA0C";

// Save mixin
@mixin icon--service-1 {
  @include icon($icon-service-1);
}

// Expose as class
.icon.icon-service-1:before {
  content: $icon-service-1;
}

// Save variable
$icon-service-2: "\EA0D";

// Save mixin
@mixin icon--service-2 {
  @include icon($icon-service-2);
}

// Expose as class
.icon.icon-service-2:before {
  content: $icon-service-2;
}

// Save variable
$icon-service-3: "\EA0E";

// Save mixin
@mixin icon--service-3 {
  @include icon($icon-service-3);
}

// Expose as class
.icon.icon-service-3:before {
  content: $icon-service-3;
}

// Save variable
$icon-download: "\EA0F";

// Save mixin
@mixin icon--download {
  @include icon($icon-download);
}

// Expose as class
.icon.icon-download:before {
  content: $icon-download;
}

// Save variable
$icon-overstappen: "\EA10";

// Save mixin
@mixin icon--overstappen {
  @include icon($icon-overstappen);
}

// Expose as class
.icon.icon-overstappen:before {
  content: $icon-overstappen;
}


