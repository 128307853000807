.band--search--change {

  @include mq($bp-medium) {
    margin-bottom: $margin-bottom * 2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-height: inherit;
    max-width: 1000px;
    min-height: inherit;

    .search {
      border: 1px solid $color-grey-light;
      border-top: 0;
      flex: 1 1 100%;
      max-width: none;

      .title {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        max-width: 800px;
        text-align: center;
      }

      .button {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .results {
      flex: 1 1 100%;
      max-width: none;

      &__holder {
        display: block;
        max-height: inherit;
        min-height: inherit;
      }

      &__list {
        border-bottom: 1px solid $color-grey-light;
        border-right: 1px solid $color-grey-light;
        height: inherit;
        max-height: inherit;
        overflow: visible;
      }

      .fc-list__logo__image {
        max-height: 50px;
      }
    }
  }

  .fc-list__logo a {
    display: block;
    min-height: 50px;
    width: 80px;

    @include mq($bp-large) {
      width: 120px;
    }

    @include mq($bp-xlarge) {
      width: 150px;
    }
  }


  .fc-list__item {
    // min-height: 100px;
    cursor: pointer;
    position: relative;

    .button {
      background: $color-grey-light;
      border: 0;
      box-sizing: border-box;
      color: $color-white !important;
      display: block;
      float: none;
      margin-bottom: 10px;
      width: calc(100% - 20px);

      @include mq($bp-medium) {
        display: inline;
        float: right;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: inherit;
      }
    }

    &:hover {
      background: $color-grey-lighter;

      .button {
        background: $color-orange !important;
      }
    }
  }


}
