.steps {
  @include clearfix();
  margin-bottom: $margin-bottom;
  padding: 10px 0;

  .band--content & {
    margin-top: 20px;
  }
}

.step {
  display: inline;
  float: left;
  position: relative;
  text-align: center;
  width: 25%;

  &:not(.step--last)::before {
    background: $color-black;
    content: '';
    height: 1px;
    left: 75%;
    overflow: hidden;
    position: absolute;
    top: 15px;
    width: 45%;
    z-index: 2;
  }

  &:not(.step--last)::after {
    background-image: url("data:image/svg+xml;utf8,<svg width='9' height='11' viewBox='0 0 9 11' xmlns='http://www.w3.org/2000/svg'><path fill='#{$color-black}' d='M0.1,10.7c-0.2-0.3-0.1-0.6,0.2-0.8l7.1-4.5L0.3,1C0,0.8-0.1,0.5,0.1,0.3C0.2,0,0.6-0.1,0.8,0.1L8.6,5 c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.4-0.3,0.5l-7.8,4.9c-0.1,0.1-0.2,0.1-0.3,0.1C0.4,10.9,0.2,10.9,0.1,10.7z'/></svg>");
    background-position: 0 50%;
    background-repeat: no-repeat;
    content: '';
    height: 11px;
    left: calc(120% - 8px);
    overflow: hidden;
    position: absolute;
    top: 10px;
    width: 9px;
    z-index: 1;
  }

  &__icon {
    @include font-size(24);
    background: $color-white;
    display: inline-block;
    height: 40px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 10%;
    position: relative;
    z-index: 3;

    @include mq(380) {
      @include font-size(32);
    }
  }

  span {
    display: none;
    font-size: 90%;
    line-height: 1.2;
    margin: 0 auto;

    @include mq($bp-small) {
      display: block;
    }

    @include mq($bp-large) {
      font-size: 80%;
    }
  }

  &--done {
    color: $color-grey;
  }

  &--selected {
    color: $color-orange;
  }
}
