.table-wrapper {
  margin-bottom: $margin-bottom;
  // scss-lint:disable PropertySpelling
  overflow-scrolling: touch;
  // scss-lint:enable PropertySpelling
}

.table {
  color: $color-grey-dark;
  margin: 0;
  text-align: left;
  width: 100%;

  &--no-border,
  &--input {
    width: inherit;
  }

  &--medium-cell-padding {

    .table__cell {
      padding-bottom: 5px !important;
      padding-top: 5px !important;
    }
  }

  &__cell {
    @include font-size(14);
    border: 1px solid $color-grey-light;
    font-weight: normal;
    line-height: 1.25;
    padding: 5px;
    text-align: left;
    vertical-align: top;

    @include mq($bp-xsmall) {
      font-size: inherit;
    }

    // scss-lint:disable IdSelector, SelectorFormat
    #aanmeldenForm & {
      @include font-size(16);
    }
    // scss-lint:enable IdSelector, SelectorFormat

    &--spacebottom {
      padding-bottom: 20px !important;
    }

    &--right {
      text-align: right;
    }

    &--data {

      &::before {
        content: attr(data-desc)': ';
        display: block;
        text-align: left;
        width: 100%;

        @include mq(360) {
          display: none;
        }
      }
    }

    &--input {
      margin-bottom: 0;
      padding: 0 2em 0 1.5em !important;
      position: relative;
      vertical-align: baseline !important;
      width: 120px;

      input {
        border: 1px solid $color-grey-light;
        height: 32px !important;
        line-height: 32px !important;
        margin: 5px 0;
        max-width: inherit !important;
        text-align: right;
      }

      span {
        left: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .icon--check,
      abbr {
        @include font-size(18);
        color: $color-green;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      abbr {
        color: $color-secondary;
        right: 7px;
      }
    }

    &--desc {
      display: none;

      @include mq(360) {
        display: table-cell;
        white-space: nowrap;
      }
    }

    &--head,
    .table--default &:first-child,
    .table--row &:first-child {
      background-color: darken($color-grey-lighter, 3) !important;
      color: darken($color-grey-dark, 10%);
      font-weight: bold;
    }

    .table--no-border &,
    .table--numbers &,
    .table--input & {
      background-color: transparent !important;
      border: 0;
      line-height: 1.25;
      padding: 0 $margin-bottom 5px 0;
    }

    .table--input & {
      // border: 1px solid red;
      padding: 5px $margin-bottom 5px 0;
      vertical-align: middle;
    }

    &--number {
      padding: 0 5px 5px !important;
      text-align: right;
      vertical-align: bottom;
      white-space: nowrap;
    }

    &--number + &--number {
      text-align: center;
      width: 1em;
    }

    &--label {
      color: $color-grey;
      white-space: nowrap !important;
      width: 120px;

      // scss-lint:disable IdSelector, SelectorFormat
      #aanmeldenForm & {
        @include font-size(15);
        width: 80px;

        @include mq($bp-medium) {
          white-space: nowrap;
          width: inherit;
        }
      }
      // scss-lint:enable IdSelector, SelectorFormat
    }
  }

  &__row:nth-child(even) {
    background-color: lighten($color-grey-lighter, 3);
  }

  &__row--results {
    .table__cell {
      border-top: 1px solid $color-grey;
      font-weight: bold;
      padding-top: 5px;
    }

    .table__cell--number + .table__cell--number {
      border-top: 0;
    }

    .table--input & {
      .table__cell:first-child {
        border-top: 0;
      }
    }

  }
}
