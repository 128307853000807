.input-file {
  @include font-size(16);
  cursor: pointer !important;
  display: block;
  font-family: $font-default;
  margin: 0;

  &::-webkit-file-upload-button {
    background: $color-grey-lighter;
    border: 1px solid $color-grey;
    border-radius: 0;
    color: $color-text;
    cursor: pointer;
    font-family: $font-default;
    margin-right: 10px;
    outline: none;
    padding: 8px 15px;
  }

  &::-ms-browse {
    background: $color-grey-lighter;
    border: 1px solid $color-grey;
    border-radius: 0;
    color: $color-text;
    cursor: pointer;
    font-family: $font-default;
    margin-right: 10px;
    outline: none;
    padding: 8px 15px;
  }

  &:focus {
    outline: none;
  }
}
