.figure {
  clear: both;
  margin: 0 auto $margin-bottom;
  max-width: 100%;

  @include mq($bp-small, true) {
    &--left,
    &--right {
      display: inline;
      max-width: 49%;
    }

    &--left {
      float: left;
      margin: 0 2% $margin-bottom 0;
    }

    &--right {
      float: right;
      margin: 0 0 $margin-bottom 2%;
    }

    &--no-margin {
      margin: 0 0 $margin-bottom;
    }
  }

  &--left {
    clear: left;
    float: left;
    margin-right: 2%;
  }

  &--right {
    clear: right;
    float: right;
    margin-left: 2%;
  }


  &--left + &--right {
    margin-left: 0;
    margin-right: 0;

    + * {
      clear: both;
    }
  }

  &--right + &--left {
    clear: both;
  }

  &--left + *:not(.title),
  &--left + .title + * {
    @include clearfix();
  }

  &--right + *:not(.title),
  &--right + .title + * {
    @include clearfix();
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  &--shadow &__image {
    box-shadow: 10px 10px 25px rgba($color-black, .1);
  }

  &__caption {
    color: tint($color-text, 50);
    display: block;
    font-size: 90%;
    line-height: 1.2;
    margin: 6px auto 0;
    text-align: center;
  }
}
