%check {
  @include font-awesome($fa-var-check);
  color: $color-green;
  left: 0;
  position: absolute;
  top: 5px;
}

// STANDAARD
.list {
  // display: inline-block;
  margin: 0 0 $margin-bottom 20px;
  text-align: left;

  &--unordered {
    list-style: disc outside;
  }

  &--ordered {
    list-style: decimal outside;
  }

  &__item {
    color: $color-orange;

    span {
      color: $color-text;

      .band__content--white & {
        color: $color-white;
      }
    }
  }
}
