.slider-holder {
  height: 30px;
  padding: 10px 15px 15px;
  position: relative;
  z-index: 2;

  &::after,
  &::before {
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 10px;
    z-index: -1;
  }

  &::before {
    background: $color-grey;
    right: 0;
  }

  &::after {
    background: $color-primary;
    width: 15px;
  }
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  transition: left 300ms, top 300ms;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base,
.noUi-handle {
  transform: translate3d(0,0,0);
}

.noUi-horizontal {
  height: 8px;
}

.noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
  left: -12px;
  top: -8px;
}

.noUi-background {
  background: $color-primary;
}

.noUi-origin {
  background: $color-grey;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-handle {
  border: 1px solid $color-grey;
  border-radius: 50%;
  background: $color-white;
  cursor: default;
}

.noUi-active {
  box-shadow: inset 0 0 1px $color-white, inset 0 1px 7px $color-grey-light, 0 3px 6px -3px $color-grey;
}
