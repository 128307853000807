$form-class: '.form-item';

.form {
  font-family: $font-title;
  margin: 0 auto;

  .site & {
    max-width: 800px;
  }

  .site .band--content & {
    max-width: 960px;
  }

  .site .band--item & {
    text-align: left;
  }

  .page & .paragraph--form {
    @include mq($bp-medium) {
      max-width: 70%;
    }
  }

  // .page & .alert .paragraph {
  //   @include mq($bp-medium) {
  //     max-width: 100%;
  //   }
  // }

  .form-item--error {
    .form-item__label {
      color: $color-status-error;
    }

    input,
    select {
      border: 1px solid $color-status-error;
    }
  }
}

.clearfix {
  @include clearfix();
}

#{$form-class} {
  @include clearfix();
  margin-bottom: $margin-bottom;

  .search--form & {
    margin-bottom: $margin-bottom / 2;
  }

  &__label {
    display: block;
    font-family: $font-title;
    padding-bottom: 3px;

    &--centered {
      text-align: center;
    }

    .fa {
      color: $color-orange;
    }

    @include mq($bp-xlarge - 1, false, max) {
      .search--form & {
        @include font-size(16);
      }
    }
  }

  &__content {
    @include clearfix();
    position: relative;

    .form--check & {
      padding-right: 30px;
      position: relative;
    }
  }

  &__info {
    // @include font-size(14);
    clear: both;
    // color: $color-grey;
    display: block;
    font-family: $font-title;
    padding-top: 3px;
    width: 100%;

    @include mq($bp-large) {
      @include font-size(16);
    }

    + #{$form-class}__info {
      padding-top: 0;
    }

    &--error {
      color: $color-status-error;
      font-weight: 700;

      #{$form-class}--check & {
        display: none;
      }

      #{$form-class}--check.false & {
        display: block;
      }
    }
  }
}

.form-check {
  @include font-size(24);
  display: none;
  line-height: 48px;
  margin-left: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 24px;

  @include mq($bp-small) {
    &--inline {
      display: inline;
      float: left;
      position: static;
    }
  }

  .fa {
    display: none;
  }

  .true & {
    color: $color-status-ok;
    display: block;

    .fa-check {
      display: inline-block;
    }
  }

  .false & {
    color: $color-status-error;
    display: block;

    .fa-times {
      display: inline-block;
    }
  }
}

.input-info {
  color: $color-grey-dark;
  font-size: 90%;
  padding-left: 2%;
  position: relative;
  top: 12px;
}

.netto-price {
  margin-bottom: 10px;
  display: inline-block;
}

@import 'input-select-textarea',
        'radio-checkbox',
        'file-input',
        'login',
        'placeholder',
        'simplesearch',
        'labels',
        'noUiSlider/nouislider',
        'noUiSlider/nouislider.tooltips',
        'noUiSlider/nouislider.pips';
