@import '../variables';
@import '../mixins';

.overlay-bars {
  background-color: transparent;
  height: 172px;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: transform 250ms ease;
  width: 150px;
  z-index: 1;

  .overlay__1 {
    background-color: #cad008;
    border-radius: 60px;
    height: 80px;
    position: absolute;
    right: 40px;
    top: -51px;
    transform: rotateZ(-44.9deg);
    transform-origin: right top;
    width: 300px;
  }

  .overlay__2 {
    background-color: #e2e68e;
    border-radius: 40px;
    height: 27px;
    position: absolute;
    right: 131px;
    top: 10px;
    transform: rotateZ(-46deg);
    transform-origin: right top;
    width: 300px;
  }

  .overlay__3 {
    background-color: #e2e68e;
    border-radius: 40px;
    height: 27px;
    position: absolute;
    right: 93px;
    top: -10px;
    width: 27px;
  }

  &.animate {
    .overlay__1 {
      animation: overlay1 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 500ms;
      opacity: 0;

    }
    .overlay__2 {
      animation: overlay2 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 1000ms;
      opacity: 0;

    }
    .overlay__3 {
      animation: overlay3 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 1200ms;
      opacity: 0;
    }
  }
}

.overlay-bars2 {
  background-color: transparent;
  height: 40vh;
  max-height: 320px;
  left: 120px;
  pointer-events: none;
  position: fixed;
  bottom: -40px;
  width: 30vw;
  z-index: 1;

  @include mq($bp-medium) {
    left: 150px;
  }

  @include mq(1200) {
    left: 20vw;
  }

  .overlay__1 {
    background-color: $color-blue;
    border-radius: 60px;
    height: 90px;
    position: absolute;
    left: 10px;
    top: 10px;
    transform: rotateZ(36deg);
    transform-origin: left top;
    width: 600px;
  }

  .overlay__2 {
    background-color: #99D9EF;
    border-radius: 60px;
    height: 60px;
    position: absolute;
    left: -30px;
    top: -60px;
    transform: rotateZ(36deg);
    transform-origin: left top;
    width: 400px;
  }

  .overlay__3 {
    background-color: #99D9EF;
    border-radius: 99999px;
    height: 60px;
    position: absolute;
    left: -110px;
    top: -95px;
    width: 60px;
  }

  &.animate {
    .overlay__1 {
      animation: overlayBar1 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 500ms;
      opacity: 0;

    }
    .overlay__2 {
      animation: overlayBar2 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 1000ms;
      opacity: 0;

    }
    .overlay__3 {
      animation: overlayBar3 1s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
      animation-delay: 1200ms;
      opacity: 0;
    }
  }
}

.overlay-circle {
  background-color: $color-green;
  border-radius: 100%;
  height: 16vw;
  pointer-events: none;
  position: absolute;
  right: -20px;
  top: 42vh;
  width: 16vw;
  min-height: 90px;
  min-width: 90px;
  max-height: 150px;
  max-width: 150px;
  display: none;

  .animate ~ & {
    animation: overlayCircle 2s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
    animation-delay: 1800ms;
    opacity: 0;
  }

  @include mq(480) {
    display: block;
  }

  @include mq($bp-large) {
    top: 54vh;
  }
}

.overlay-circle2 {
  background-color: #fff;
  border-radius: 100%;
  border: 24px solid #e64e22;
  height: 12vw;
  pointer-events: none;
  position: absolute;
  left: 58vw;
  top: -60px;
  width: 12vw;
  min-height: 50px;
  min-width: 50px;
  max-height: 150px;
  max-width: 150px;
  display: none;

  .animate ~ & {
    animation: overlayCircle2 4s cubic-bezier(0.45, 0.84, 0.25, 0.75) 1 forwards;
    animation-delay: 1200ms;
    opacity: 0;
  }

  @include mq(600) {
    display: block;
  }

  @include mq($bp-medium) {
    left: 60vw;
    top: -90px;
    border: 30px solid #e64e22;
  }

  @include mq(1280) {
    top: -60px;
  }
}

@keyframes overlay1 {  0% { top: 120px; right: 200px; opacity: 0; } 100% { top: -51px; right: 40px; opacity: 1; } }
@keyframes overlay2 {  0% { top: 120px; right: 200px; opacity: 0; } 100% { top: 10px; right: 131px; opacity: 1; } }
@keyframes overlay3 {  0% { top: 120px; right: 200px; opacity: 0; } 100% { top: -10px; right: 93px; opacity: 1; } }

@keyframes overlayBar1 {  0% { transform: translate(400px, 300px) rotateZ(36deg); opacity: 0; } 100% { transform: translate(0, 0) rotateZ(36deg); opacity: 1; } }
@keyframes overlayBar2 {  0% { transform: translate(400px, 300px) rotateZ(36deg); opacity: 0; } 100% {  transform: translate(0, 0) rotateZ(36deg); opacity: 1; } }
@keyframes overlayBar3 {  0% { transform: translate(400px, 300px); opacity: 0; } 100% {  transform: translate(0, 0); opacity: 1; } }

@keyframes overlayCircle {  0% { transform: translate(40px, 30px) scale(.6); opacity: 0; } 100% {  transform: translate(0, 0) scale(1); opacity: 1; } }
@keyframes overlayCircle2 {  0% { transform: translate(0, -150px) scale(.8); opacity: 0; } 100% {  transform: translate(0, 0) scale(1); opacity: 1; } }
