.site .form--simplesearch {
  @include clearfix();
  max-width: inherit !important;
  padding: 20px 0;
  text-align: center !important;

  @include mq($bp-medium, false, max) {
    background: $color-grey-lighter;
    border: 1px solid $color-grey-light;
    padding: 10px;
  }

  .form-item {
    @include mq($bp-medium) {
      display: inline;
      float: left;
      width: 80%;
    }

    &__label {
      font-weight: bold;
      line-height: 1.3;
      padding-bottom: 7px;

      @include mq($bp-medium) {
        height: 27px;
        margin-bottom: 0;
        min-height: 27px;
        padding-bottom: 3px;
        text-align: left;
      }
    }
  }

  .form-item__field--text {
    max-width: 100%;
  }

  .form-item__content--medium {
    @include clearfix();
    margin-bottom: $margin-bottom / 2;

    @include mq($bp-small) {
      display: inline;
      float: left;
      margin: 0 1% 0 0;
      width: 49%;
    }

    + .form-item__content--medium {
      margin-bottom: 0;

      @include mq($bp-small) {
        margin: 0 0 0 1%;
      }
    }
  }

  .btn {
    height: 48px;
    line-height: 48px;
    margin-bottom: 5px;
    padding-bottom: 0;
    padding-top: 0;

    @include mq($bp-medium) {
      margin-top: 30px;
      width: 100%;
    }
  }

  .form-actions {
    @include mq($bp-medium) {
      display: inline;
      float: left;
      margin-left: 1%;
      width: 19%;
    }

    a {
      font-size: 90%;
    }
  }
}
