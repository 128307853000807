.noUi-tooltip {
  @include font-size(14);
  bottom: -24px;
  color: $color-text;
  display: block;
  left: 50%;
  padding: 0;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}
