%button {
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  margin-bottom: $margin-bottom;
  min-width: 140px;
  outline: none;
  position: relative;
  text-align: center;
  text-decoration: none;
  // text-transform: uppercase;
  transition: all 200ms ease;
  border-radius: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.button,
.btn,
.cta {
  @extend %button;
  @include font-size(16);
  background-color: $color-orange;
  border: 2px solid $color-orange;
  color: $color-white;
  padding: 10px 20px;

  @include mq($bp-small) {
    + .button,
    + .btn,
    + .cta {
      margin-left: $margin-bottom / 2;
    }
  }

  @include mq($bp-medium) {
    @include font-size(18);
    padding: 15px 30px;

    &--right {
      float: right;
    }
  }

  .paragraph & {
    margin-top: 10px;
  }

  &:hover {
    background: $color-orange-hover;
    border-color: $color-orange-hover;
    color: $color-white;
  }

  &.grijs {
    background-color: $color-grey-lighter;
    border: 2px solid $color-grey-lighter;
    color: $color-grey;

    &:hover {
      background: $color-grey-light;
      border-color: $color-grey-light;
    }
  }

  &.blauw {
    background-color: $color-blue;
    border: 2px solid $color-blue;

    &:hover {
      background: $color-blue-hover;
      border-color: $color-blue-hover;
    }
  }

  &.lichtblauw {
    background-color: tint($color-blue, 50);
    border: 2px solid tint($color-blue, 50);

    &:hover {
      background: tint($color-blue-hover, 50);
      border-color: tint($color-blue-hover, 50);
    }
  }

  &.groen {
    background-color: $color-green;
    border: 2px solid $color-green;

    &:hover {
      background: $color-green-hover;
      border-color: $color-green-hover;
    }
  }

  &--back {
    background-color: $color-grey-light;
    border: 2px solid $color-grey-light;
    color: $color-white;
    min-width: inherit;
    float: left;
    display: inline;
    margin-right: 10px;

    &:hover {
      background: $color-grey;
      border-color: $color-grey;
    }
  }

  &--next {
    float: right;
    display: inline;
    margin-left: 10px;
  }

  &--disabled {
    background-color: $color-grey-light;
    border: 2px solid $color-grey-light;
    color: $color-white;
    cursor: not-allowed;

    &:hover {
      background-color: $color-grey-light;
      border: 2px solid $color-grey-light;
      color: $color-white;
    }
  }

  &--hidden {
    display: none;
  }
}

.cta:not(.fit) {
  display: block;
}

.fc-list + .button {
  left: 50%;
  margin: 15px auto;
  position: relative;
  transform: translateX(-50%);
}
