






.fc-list {
  // border-bottom: 1px solid $color-grey-light;
  font-family: $font-title;
  margin: 0;

  &__item {
    border-top: 1px solid $color-grey-light;
    padding: 15px 0 10px 20px;

    @include mq($bp-large) {
      display: flex;

      .no-flexbox & {
        @include clearfix();
      }
    }

    &:first-child {
      border-top: 0;
    }


    &--total {
      @include unselectable();
      @include font-size(14);
      background-color: $color-grey-lighter;
      color: $color-grey-dark;
      display: block;
      padding: 10px 20px;
      text-align: right;

      span {
        color: $color-text;
        font-weight: bold;

        // &:first-child {
        //   color: $color-secondary;
        // }
      }
    }
  }

  &__col {
    @include clearfix();

    @include mq($bp-large) {
      flex: 1 1 60%;

      .no-flexbox & {
        display: inline;
        flex: none;
        float: left;
        width: 60%;
      }

      &--table {
        flex: 1 1 40%;

        .no-flexbox & {
          width: 40%;
        }
      }
    }

    &:first-child {
      margin-bottom: 10px;

      @include mq($bp-large) {
        margin-bottom: 0;
      }
    }

    .title {
      @include font-size(16);
      margin-bottom: 3px;

      @include mq($bp-large) {
        @include font-size(18);
      }
    }

    .table {
      margin-bottom: $margin-bottom / 2;
      width: 100%;

      @include mq($bp-large) {
        margin-bottom: $margin-bottom;
      }
    }

    .link {
      color: $color-orange;

      &:hover {
        color: $color-orange-hover;
      }
    }

    .paragraph {
      margin-bottom: 0;
    }

  }

  &__info {
    display: inline;
    float: left;
    width: calc(100% - 110px);

    @include mq($bp-large) {
      width: calc(100% - 140px);
    }

    @include mq($bp-xlarge) {
      width: calc(100% - 190px);
    }
  }

  &__logo {
    display: inline;
    float: left;
    margin-right: 10px;
    text-align: center;
    width: 80px;

    @include mq($bp-large) {
      width: 120px;
    }

    @include mq($bp-xlarge) {
      margin-right: 20px;
      width: 150px;
    }

    &__image {
      display: block;
      margin: 0 auto;
      max-height: 120px;
      max-width: 100%;
    }
  }
}

.fc-list-new {
  font-family: inherit;

  .fc-list__item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mq($bp-small) {
      flex-wrap: nowrap;
    }
  }

  .fc-list__col.fc-list__col--table {
    flex: 0 100%;

    .cta {
      white-space: nowrap;
    }

    @include mq($bp-small) {
      flex: 0 auto;
    }
  }
}
