hr {
  border: 0;
  border-bottom: 1px solid $color-grey-light;
  clear: both;
  display: block;
  height: 1px;
  margin-bottom: $margin-bottom * 1.5;
  overflow: hidden;
  width: 100%;
}

.sample-calc {
  position: relative;

  .band__content &:not(.sample-calc--no-shadow) {
    @include clearfix();
    background: $color-white;
    border-radius: 5px;
    box-shadow: 0 0 10px 10px transparentize($color-black, .9);
    margin-bottom: $margin-bottom;
    padding: 20px;

    @include mq(1100) {
      width: 120%;
      max-width: 812px;
      box-sizing: border-box;
    }
  }

  &__item {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    margin-bottom: $margin-bottom;
    min-height: 32px;

    @include mq(360) {
      align-items: stretch;
    }

    @include mq($bp-xsmall) {
      margin-bottom: $margin-bottom / 4;
    }

    &--result {
      align-items: center;
      border-top: 2px solid $color-grey;
      font-weight: bold;
      margin: $margin-bottom 0 ($margin-bottom * 1.5);
      padding-top: $margin-bottom;
    }

    .no-flexbox & {
      @include clearfix();
      display: block;
    }

    &--tax {
      @include mq($bp-small - 1, false, max) {
        flex-wrap: wrap;
      }

      .fc-modal & {

        @include mq($bp-large) {
          @include mq(1300 - 1, false, max) {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  &__col {
    flex: 1 1 auto;
    line-height: 1.1;

    .no-flexbox & {
      display: inline;
      flex: none;
      float: left;
    }

    &--tax {
      @include mq($bp-small - 1, false, max) {
        flex: 0 0 100%;
      }

      .fc-modal & {

        @include mq($bp-large) {
          @include mq(1300 - 1, false, max) {
            flex: 0 0 100%;
          }
        }

        // &:nth-child(2) {
        //   flex: 0 1 200px !important;
        // }

          // @include mq($bp-medium) {
          //   flex: 0 0 100% !important;
          //   border: 1px solid red;
          // }
        // }
      }
    }

    &:nth-child(1) {
      span {
        color: $color-grey;
        display: block;

        @include mq($bp-xsmall) {
          display: inline;
        }
      }
    }

    &:nth-child(2) {
      flex: 0 1 120px;
      text-align: right;
      // white-space: nowrap;

      .no-flexbox & {
        display: inline;
        flex: none;
        float: right;
        min-width: 120px;
      }

      .sample-calc__item--field &,
      .sample-calc__item--result & {
        flex: 1 0 120px;
      }

      .sample-calc__item--result & {
        @include font-size(24);
        color: $color-blue;

        @include mq($bp-small, false, max) {
          .no-flexbox & {
            clear: left;
            display: block;
            flex: none;
            float: none;
            width: 100%;
          }
        }
      }
    }

    &--info {
      span {
        color: $color-text !important;

        strong {
          color: $color-orange !important;
          font-size: 120%;
          font-weight: normal;
        }
      }
    }
  }

  &__info {
    color: $color-grey;
    font-size: 80%;

    @include mq($bp-small) {
      bottom: 8px;
      left: 20px;
      position: absolute;
      right: 20px;
    }

    &--v2 {
      clear: both;
      font-size: 12px;
      position: static !important;
    }

    strong {
      font-size: 120%;
      font-weight: normal;
    }
  }
}
