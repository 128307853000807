// settings / includes
$mobile-menu:           false;
$owl-carousel:          true;
$default-border-radius: 3px;

// breakpoints
$bp-xsmall:             480;
$bp-small:              600;
$bp-medium:             768;
$bp-large:              1024;
$bp-xlarge:             1280;
$bp-max:                1490;

// default colors
$color-black:           #000000;
$color-grey-darker:     #2c2c2c;
$color-grey-dark:       #444444;
$color-grey:            #a6a6a6;
$color-grey-light:      #d2d2d2;
$color-grey-lighter:    #f5f5f5;
$color-white:           #ffffff;

$color-social-twitter:  #35ccff;
$color-social-facebook: #03539e;
$color-social-linkedin: #217bc1;

// custom colors
$color-body:            $color-grey-lighter;
$color-text:            $color-grey-darker;

$color-green:           #CAD008;
$color-green-hover:     darken($color-green, 5%);
$color-orange:          #E84F23;
$color-orange-hover:    darken($color-orange, 5%);
$color-blue:            #00A0D7;
$color-blue-hover:      darken($color-blue, 5%);

$color-primary:         $color-green;
$color-primary-hover:   shade($color-primary, 20);
$color-secondary:       $color-orange;
$color-tertiary:        $color-green;

$color-status-error:    #e41b13;
$color-status-ok:       $color-green;
$color-status-info:     $color-blue;

// typography
$font-default:          Ubuntu, Lucida Grande, Tahoma, sans-serif;
// $font-alt:              'alverata-informal-pe', sans-serif;
$font-title:            Rubik, Lucida Grande, Tahoma, sans-serif;
$font-code:             Monaco, Consolas, 'Lucida Console', monospace;

$font-lineheight:       1.5;
$font-lineheight-alt:   1.1;
$font-lineheight-title: 1.2;

// margins
$gutter:                20px;
$margin-bottom:         20px;
