h1,
.title {
  @include title-font();
  font-style: italic;
  text-transform: uppercase;
  display: block;
  margin-bottom: $margin-bottom * 1 / 2;
  margin-top: 0;

  .centered & {
    text-align: center;
  }

  .link {
    text-decoration: none;
  }

  span {
    color: $color-blue;

    &.small {
      color: $color-grey;
      font-size: 50%;
      font-weight: normal;
      letter-spacing: 1px;
    }
  }

  &--h2 {
    @include font-size(28);

    @include mq($bp-large) {
      @include font-size(32);
    }

    //@include mq($bp-xlarge) {
    //  @include font-size(36);
    //}
  }

  &--h3 {
    @include font-size(28);

    @include mq($bp-xlarge) {
      @include font-size(32);
    }

    .modal & {
      margin-bottom: $margin-bottom;
    }
  }

  &--h4 {
    @include font-size(28);
  }

  &--h5,
  &--h6 {
    @include font-size(24);
  }
}

h1 {
  @include font-size(28);

  @include mq($bp-medium) {
    @include font-size(32);
  }

  @include mq($bp-large) {
    @include font-size(36);
  }

  @include mq($bp-xlarge) {
    @include font-size(40);
  }
}

.aaoinformatie h2,
.aaoinformatie h3 {
  @include font-size(22);
  @include title-font();
  display: block;
  margin-bottom: $margin-bottom * 1 / 2;
  margin-top: 0;
}

.pageitem--news {
  h2 {
    @include title-font();
    @include font-size(28);

    @include mq($bp-large) {
      @include font-size(32);
    }

    @include mq($bp-xlarge) {
      @include font-size(36);
    }
  }
}


