#{$form-class}-list {
  list-style: none;
  margin: 0;
  padding: 0;

  input {
    display: none;
  }

  &__item {
    margin-bottom: 10px;
    margin-top: 5px;
    position: relative;

    @include mq($bp-small) {
      display: inline-block;
      float: left;
      margin-right: 1em;
      min-width: 90px;

      &--new-line {
        clear: left;
      }
    }

    .search--form & {
      @include mq($bp-xlarge - 1, false, max) {
        @include font-size(16);
      }
    }

    &--block {
      border-bottom: 1px solid $color-grey-light;
      margin-bottom: $margin-bottom / 2;

      @include mq($bp-small) {
        clear: left;
        display: block;
        margin-bottom: $margin-bottom;
        width: 100%;
      }

      @include mq($bp-large) {
        border-bottom: 0;
      }
    }

    &--price {
      box-sizing: border-box;
      position: relative;
    }

    .fc-modal &.active {
      @include mq($bp-large) {
        &::after {
          background: $color-white;
          bottom: 3px;
          box-shadow: 5px 0 20px 0 transparentize($color-black, .9);
          content: '';
          left: -7px;
          position: absolute;
          right: -20px;
          top: -12px;
          z-index: 1;
        }

        .price {
          color: $color-white;
          font-weight: bold;
          z-index: 5;
          top: 50%;
          transform: translateY(-100%);

          &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            background: $color-blue;
            z-index: -1;
            left: -5px;
            right: 0;
            transform: translateY(-50%);
            height: 28px;
          }

          &::before {
            content: '';
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 100%;
            height: 0;
            width: 0;
            border: solid transparent;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-left-color: $color-blue;
            border-width: 14px;
          }
        }
      }
    }


  }

  label {
    position: relative;
    z-index: 2;
  }

  &__label {

    span {
      @include unselectable();
      display: inline-block;
      line-height: 1.1;
      padding-left: 30px;
      position: relative;
      vertical-align: bottom;
      z-index: 2;

      .error & {
        color: $color-status-error;
      }

      &::before {
        background: $color-white;
        border: 1px solid $color-grey;
        content: '';
        cursor: pointer;
        height: 22px;
        left: 0;
        position: absolute;
        top: -5px;
        width: 22px;
        z-index: 1;

        .error & {
          border-color: $color-status-error;
        }
      }

      #{$form-class}-list__item--price & {
        padding-right: 120px;
      }
    }

    // scss-lint:disable IdSelector, SelectorFormat
    #aanmeldenForm &--checkbox {
      span {
        line-height: 1.25;

        @include mq($bp-medium) {
          line-height: 1.5;
        }

        &::before {
          top: -2px;

          @include mq($bp-large) {
            top: 0;
          }
        }
      }
    }// scss-lint:enable IdSelector, SelectorFormat

    &--radio {
      display: block;
      line-height: 1.1;

      span::before {
        border-radius: 50%;
      }
    }

    #{$form-class}-list__checkbox:checked + span {

      &::after {
        @include font-size(17);
        background: url('../images/check.svg') 50% no-repeat;
        background-size: contain;
        color: $color-status-ok;
        content: '';
        cursor: pointer;
        height: 18px;
        left: 3px;
        position: absolute;
        text-align: center;
        top: 2px;
        width: 18px;
        z-index: 2;

        .search--form & {
          top: -2px;
        }
      }

      .error &::before {
        border-color: $color-grey;
      }
    }

    #{$form-class}-list__radio:checked + span {

      &::after {
        background-color: $color-status-ok;
        border-radius: 50%;
        content: '';
        cursor: pointer;
        height: 14px;
        left: 5px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 14px;
        z-index: 2;

        .fc-modal & {
          background-color: $color-blue;
        }
      }

      .error &::before {
        border-color: $color-grey;
      }
    }
  }

  .price {
    // background: yellow;
    left: auto;
    line-height: 1.1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .info {
    @include font-size(12);
    line-height: 1.25;
    padding: 5px 0 10px 30px;
    position: relative;
    z-index: 2;

    @include mq($bp-xlarge) {
      @include font-size(14);
      padding-right: 120px;
    }
  }
}

.form-item-list--new {
  font-size: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .form-item-list__item {
    width: 100%;
    margin-right: 0;

    @include mq($bp-xsmall) {
      width: 50%;
    }

    @include mq($bp-medium) {
      width: 100%;
    }

    @include mq($bp-large) {
      width: 50%;
    }
  }

  .form-item-list__label span {
    display: block;

    &:before {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
