@import '../variables';
@import '../mixins';

.login-content {
  .title--h2 {
    @include mq($bp-large) {
      @include font-size(40);
    }
  }

  p.paragraph {
    @include font-size(14);

    @include mq($bp-large) {
      @include font-size(16);
    }

    a {
      color: $color-blue;

      &:hover {
        color: $color-blue-hover;
      }
    }
  }

  .usp {


    @include mq($bp-large) {
      display: flex;
      justify-content: space-between;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        flex: 0 0 60px;
        width: 60px;
        margin-right: 10px;
        position: relative;
        text-align: right;

        img {
          max-height: 60px;
          max-width: 100%;
          display: block;
          margin: 0 auto;

          @include mq($bp-large) {
            margin: 0 0 0 auto;
          }

        }

        @include mq($bp-large) {
          flex: 0 0 80px;
          width: 80px;

          img {
            max-height: 80px;
          }
        }


      }

      .text {
        margin-right: 10px;
        text-transform: uppercase;
        line-height: 1.2;
        @include font-size(12);
        max-width: 180px;

        @include mq($bp-large) {
          @include font-size(12);
          margin-right: 20px;
          max-width: inherit;
        }

        strong {
          font-weight: 900;
        }
      }
    }
  }
}


