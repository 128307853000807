$search-height: 60vh;
$search-min-height: 680px;
$search-tab-height: 50px;

.band--search {
  background-color: $color-body;
  border-top: 1px solid $color-grey-light;
  position: relative;

  @include mq($bp-medium) {
    display: flex;
    flex-wrap: wrap;

    .no-flexbox & {
      @include clearfix();
    }
  }
}

.search--form {
  padding: 0;

  @include mq($bp-medium) {
    flex: 1 1 40%;
    max-width: 40%;
    overflow-x: hidden;
    overflow-y: auto;

    .no-flexbox & {
      display: inline;
      flex: none;
      float: left;
      width: 40%;
    }
  }

  @include mq($bp-large) {
    flex: 1 1 36%;
  }

  @include mq(1200) {
    flex: 1 1 33.33%;
  }

  &__wrapper {
    border-bottom: 1px solid $color-grey-light;
    padding: 20px !important;

    @include mq($bp-medium) {
      border-bottom: 0;
    }

    .form,
    .title,
    .search-results {
      transform: translateZ(0);
    }
  }

  .title {
    @include text-overflow();
  }

  > * {
    padding: 0 20px;
  }

  &__item {
    display: none;

    @include mq($bp-medium) {
      display: block;
    }
  }

  &__button {
    margin-right: 10px;

    @include mq($bp-medium) {
      @include mq(901 - 1, false, max) {
        @include font-size(16);
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .reset-results {
    clear: left;
    color: $color-grey;
    display: block;
    text-decoration: none;
    white-space: nowrap;

    @include mq(400) {
      @include mq($bp-medium - 1, false, max) {
        clear: none;
        float: right;
        position: relative;
        top: 14px;
      }
    }

    @include mq($bp-medium) {
      clear: none;
      float: right;
      position: relative;
      top: 14px;

      @include mq($bp-large - 1, false, max) {
        font-size: 14px;
      }
    }

    &:hover {
      color: $color-grey-dark;
    }

    .fa {
      padding-right: 6px;
    }
  }
}

.results {
  background-color: $color-white;
  display: block;
  position: relative;
  width: 100%;

  @include mq($bp-medium) {
    flex: 1 1 60%;
    max-width: 60%;
    position: relative;

    .no-flexbox & {
      display: inline;
      float: left;
      width: 60%;
    }

    &::after {
      background-color: $color-grey-light;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 1px;
      z-index: 3;
    }
  }

  @include mq($bp-medium - 1, false, max) {

    &__map {
      display: none !important;
    }

    &__list {
      display: block !important;
    }

  }

  &__holder {
    position: relative;
  }

  &__map {
    height: 70vh;
  }

  &__map,
  &__list {
    background-color: $color-white;
    display: none;
    min-height: 560px !important;
    overflow-x: hidden;
    overflow-y: visible;
    position: relative;

    @include mq($bp-medium) {
      flex: 1 0 100%;
      min-height: inherit;
    }

    &.is-visible {
      display: block;
      overflow: visible;
    }

    .fa-spinner,
    .fa-spin {
      left: 10px !important;
      position: absolute !important;
      top: 62px !important;
    }

    iframe {
      display: block;
      height: 100% !important;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }

  &__list {

    .table__cell {
      width: 50%;
    }
  }
}

.results__map {
  &__link {
    background: $color-white;
    border: 1px solid $color-grey-light;
    display: inline-block !important;
    left: 10px;
    padding: 0 20px 0 46px !important;
    position: absolute !important;
    top: 10px;
    z-index: 9;

    span {
      display: inline !important;
    }
  }
}

.result-msg {
  background: tint($color-status-info, 90);
  border: 2px solid $color-status-info;
  display: block;
  line-height: 1.2;
  margin: 10px;
  padding: 10px;

  .band--search--change & {
    margin: 0 20px 40px;
    position: relative;
    top: 20px;
  }
}

// @import
@import 'list',
        'tags',
        'sort',
        'change';

// Google maps Infowindow
.info {

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }

  &__image {
    display: block;
    margin-right: 10px;
    max-width: 100%;
  }

  &__title {
    @include font-size(14);
    line-height: 1.2;
  }

  table {
    clear: both;
    margin-bottom: 5px;
  }

  &__link {
    font-weight: bold;
  }
}
