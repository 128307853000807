.link {
  color: $color-secondary;
  text-decoration: underline;

  &:hover {
    color: $color-primary;
  }

  .band--footer & {
    color: $color-blue;
  }
}

.link--download,
.project__link {
  @include icon($icon-download);
  color: $color-orange;
  display: inline-block;
  padding: 3px 0;
  text-decoration: none;

  &::before {
    padding-right: 3px;
    position: relative;
    top: -2px;
    vertical-align: middle;
  }
}

@import 'buttons';
